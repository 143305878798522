import Link from "next/link";
import styles from "./Notification.module.scss";
import dayjs from "~/lib/dayjs";

const Notification: React.FunctionComponent = () => {
  const isShow = dayjs().tz().isBefore(new Date("2024/12/15"));

  return (
    <>
      {(isShow && (
        <Link
          href="https://airdoor.notion.site/15b3e1e11169801fad56d1dd8dd526a7"
          prefetch={false}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.notificationBanner}>
            【障害報告・お詫び】物件問い合わせができなかった方へ
          </div>
        </Link>
      )) ||
        ""}
    </>
  );
};

export default Notification;
