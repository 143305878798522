import Image from "next/image";
import Link from "next/link";
import { FC } from "react";
import styles from "@/layouts/AppStoreBanner/AppStoreBanner.module.scss";
import appIcon from "public/static/images/icon/airdoor-app-icon.png";
import Cross from "public/static/images/icon/cross.svg";
import Stars from "public/static/svgs/header/rating-stars.svg";
import { APP_OPEN_LINK } from "~/const/app";

type Props = {
  updateIsHideAppBanner: () => void;
};

const AppStoreBanner: FC<Props> = ({ updateIsHideAppBanner }) => (
  <div className={styles.bannerContainer}>
    <div className={styles.leftContent}>
      <div className={styles.closeButton} onClick={updateIsHideAppBanner}>
        <Cross />
      </div>
      <Image src={appIcon} alt="エアドアアプリアイコン" width={40} height={40} />
      <div>
        <p className={styles.text}>もっと便利なアプリが登場！</p>
        <div className={styles.rating}>
          <Stars />
          <span className={styles.grayText}>無料</span>
        </div>
      </div>
    </div>
    <Link className={styles.installLink} href={APP_OPEN_LINK} target="_blank">
      インストール
    </Link>
  </div>
);

export default AppStoreBanner;
